
import Client from "./client";
export default class User extends Client{
    constructor() {
        super();
    }
    login(email,password){
        var data = {email,password};
        return new Promise(function(resolve,reject){
            this.postLogin("auth/login",data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    listingUser(offset,limit,search,status,package_id){
        return new Promise(function(resolve,reject){
            this.get(`users/list?offset=${offset}&limit=${limit}&search=${search}&status=${status}&package_id=${package_id}`)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    save(data){
        return new Promise(function(resolve,reject){
            this.post(`users/create`,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    suspend(data){
        return new Promise(function(resolve,reject){
            this.post(`users/suspend`,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    detail(id){
        return new Promise(function(resolve,reject){
            this.get(`users/detail/${id}`)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    counter(){
        return new Promise(function(resolve,reject){
            this.get(`users/counter`)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    active(id){
        return new Promise(function(resolve,reject){
            this.put(`users/active/${id}`)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    edit(data){
        return new Promise(function(resolve,reject){
            this.post(`users/update`,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}
