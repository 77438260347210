
import Payments from "./../../api/payments";
const payments = new Payments();
export const state = {
    invoices : {list:[],has_more:0},
    subscriptions : {list:[],has_more:0},
    offset : 0,
    limit : 1,
    limitSub : 1,
    package_id : "",
    package : null,
    loading:false,
    loadingSub:false,
    error : false,
    message : ""
}

export const mutations = {
    SET_INVOICES(state,value){
        state.invoices = value
    },
    SET_SUBSCRIPTIONS(state,value){
        state.subscriptions = value
    },
    SET_OFFSET(state,value){
        state.offset = value
    },
    SET_LIMITSUB(state,value){
        state.limitSub = value
    },
    SET_LIMIT(state,value){
        state.limit = value
    },
    SET_SEARCH(state,value){
        state.search = value
    },
    SET_PACKAGE_ID(state,value){
        state.package_id = value
    },
    SET_PACKAGE(state,value){
        state.package = value
    },
    SET_LOADING(state,Value) {
        state.loading = Value
    },
    SET_LOADINGSUB(state,Value) {
        state.loadingSub = Value
    },
    SET_ERROR(state,Value) {
        state.error = Value
    },
}

export const getters = {
    subscriptions(state){
        return state.subscriptions;
    },
    invoices(state){
        return state.invoices;
    },
    offset(state){
        return state.offset;
    },
    limitSub(state){
        return state.limitSub;
    },
    limit(state){
        return state.limit;
    },
    search(state){
        return state.search;
    },
    package_id(state){
        return state.package_id;
    },
    package(state){
        return state.package;
    },
    loading(state){
        return state.loading
    },
    loadingSub(state){
        return state.loadingSub
    },
    error(state){
        return state.error
    },
}

export const actions = {
    listing(context,email){
        const {commit,getters} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            payments.listing(getters["limit"],email)
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    commit('SET_INVOICES', result.data);
                    resolve(result);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
    subscriptions(context,email){
        const {commit,getters} = context
        commit('SET_LOADINGSUB', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            payments.subscription(getters["limitSub"],email)
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADINGSUB', false);
                    commit('SET_ERROR', false);
                    commit('SET_SUBSCRIPTIONS', result.data);
                    resolve(result);
                }else{
                    commit('SET_LOADINGSUB', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADINGSUB', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    }
}