
import Setting from "./../../api/setting";
const setting = new Setting();
export const state = {
    setting : {},
    loading:false,
    error : false,
}

export const mutations = {
    SET_SETTING(state,value){
        state.setting = value
    },
    SET_LOADING(state,Value) {
        state.loading = Value
    },
    SET_ERROR(state,Value) {
        state.error = Value
    },
}

export const getters = {
    setting(state){
        return state.setting;
    },
    loading(state){
        return state.loading
    },
    error(state){
        return state.error
    },
}

export const actions = {
    create(context,data){
        const {commit,dispatch} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            setting.setSetting(data)
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    // commit('SET_USERS', result.data);
                    dispatch("detail")
                    resolve(result);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
    detail(context){
        const {commit,getters} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            setting.getSetting()
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    commit('SET_SETTING', result.data);
                    resolve(result.data);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
}