import store from '@/state/store'

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/home'),
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/users/lists'),
  },
  {
    path: '/users/create',
    name: 'users/create',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/users/create'),
  },
  {
    path: '/users/detail/:id',
    name: 'users/detail',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/users/detail'),
  },
  {
    path: '/users/edit/:id',
    name: 'users/edit',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/users/edit'),
  },
  {
    path: '/packages',
    name: 'packages',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/packages/list'),
  },
  {
    path: '/packages/create',
    name: 'packages/create',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/packages/create'),
  },
  {
    path: '/packages/edit/:id',
    name: 'packages/edit',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/packages/edit'),
  },
  {
    path: '/setting',
    name: 'setting',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/setting/setting'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/utility/404').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]
