
import Client from "./client";
export default class Payments extends Client{
    constructor() {
        super();
    }
    listing(limit,email){
        return new Promise(function(resolve,reject){
            this.get(`payments/invoice?limit=${limit}&email=${email}`)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    subscription(limit,email){
        return new Promise(function(resolve,reject){
            this.get(`payments/subcriptions?limit=${limit}&email=${email}`)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}
