
import Client from "./client";
export default class Setting extends Client{
    constructor() {
        super();
    }
    getSetting(){
        return new Promise(function(resolve,reject){
            this.get(`setting`)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    setSetting(data){
        return new Promise(function(resolve,reject){
            this.postFormdata(`setting`,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}
