
import Packages from "./../../api/packages";
const packages = new Packages();
export const state = {
    packages : {list:[],total:0},
    offset : 0,
    limit : 10,
    search : "",
    package_id : "",
    package : null,
    loading:false,
    error : false,
    message : ""
}

export const mutations = {
    SET_PACKAGES(state,value){
        state.packages = value
    },
    SET_OFFSET(state,value){
        state.offset = value
    },
    SET_LIMIT(state,value){
        state.limit = value
    },
    SET_SEARCH(state,value){
        state.search = value
    },
    SET_PACKAGE_ID(state,value){
        state.package_id = value
    },
    SET_PACKAGE(state,value){
        state.package = value
    },
    SET_LOADING(state,Value) {
        state.loading = Value
    },
    SET_ERROR(state,Value) {
        state.error = Value
    },
}

export const getters = {
    packages(state){
        return state.packages;
    },
    offset(state){
        return state.offset;
    },
    limit(state){
        return state.limit;
    },
    search(state){
        return state.search;
    },
    package_id(state){
        return state.package_id;
    },
    package(state){
        return state.package;
    },
    loading(state){
        return state.loading
    },
    error(state){
        return state.error
    },
}

export const actions = {
    listing(context){
        const {commit,getters} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            packages.listing(getters["offset"],getters["limit"],getters["search"])
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    commit('SET_PACKAGES', result.data);
                    resolve(result);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
    create(context,data){
        const {commit} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            packages.save(data)
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    // commit('SET_USERS', result.data);
                    resolve(result);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
    detail(context,id){
        const {commit,getters} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            packages.detail(id)
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    commit('SET_PACKAGE', result.data);
                    resolve(result.data);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
    edit(context,data){
        const {commit,getters,dispatch} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            packages.edit(data,data.id)
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    dispatch('detail',data.id)
                    resolve(result.data);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
}