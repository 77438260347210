
import User from "./../../api/users";
const user = new User();
export const state = {
    users : {list:[],total:0},
    offset : 0,
    limit : 10,
    search : "",
    status : "",
    package_id : "",
    user : null,
    loading:false,
    error : false,
    message : "",
    counter : {}
}

export const mutations = {
    SET_USERS(state,value){
        state.users = value
    },
    SET_COUNTER(state,value){
        state.counter = value
    },
    SET_OFFSET(state,value){
        state.offset = value
    },
    SET_LIMIT(state,value){
        state.limit = value
    },
    SET_SEARCH(state,value){
        state.search = value
    },
    SET_STATUS(state,value){
        state.status = value
    },
    SET_PACKAGE_ID(state,value){
        state.package_id = value
    },
    SET_USER(state,value){
        state.user = value
    },
    SET_LOADING(state,Value) {
        state.loading = Value
    },
    SET_ERROR(state,Value) {
        state.error = Value
    },
}

export const getters = {
    users(state){
        return state.users;
    },
    counter(state){
        return state.counter;
    },
    offset(state){
        return state.offset;
    },
    limit(state){
        return state.limit;
    },
    search(state){
        return state.search;
    },
    status(state){
        return state.status;
    },
    package_id(state){
        return state.package_id;
    },
    user(state){
        return state.user;
    },
    loading(state){
        return state.loading
    },
    error(state){
        return state.error
    },
}

export const actions = {
    listUser(context){
        const {commit,getters} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            user.listingUser(getters["offset"],getters["limit"],getters["search"],getters["status"],getters["package_id"])
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    commit('SET_USERS', result.data);
                    resolve(result);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
    create(context,data){
        const {commit} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            user.save(data)
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    // commit('SET_USERS', result.data);
                    resolve(result);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
    counter(context){
        const {commit,getters} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            user.counter()
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    commit('SET_COUNTER', result.data);
                    resolve(result.data);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
    detail(context,id){
        const {commit,getters} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            user.detail(id)
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    commit('SET_USER', result.data);
                    resolve(result.data);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
    suspend(context,data){
        const {commit,getters,dispatch} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            user.suspend(data)
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    dispatch('detail',data.id)
                    resolve(result.data);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
    active(context,id){
        const {commit,getters,dispatch} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            user.active(id)
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    dispatch('detail',id)
                    resolve(result.data);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
    edit(context,data){
        const {commit,getters,dispatch} = context
        commit('SET_LOADING', true);
        commit('SET_ERROR', false);
        return new Promise((resolve, reject) => {
            user.edit(data)
            .then((result)=>{
                if(result.status){
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', false);
                    dispatch('detail',data.id)
                    resolve(result.data);
                }else{
                    commit('SET_LOADING', false);
                    commit('SET_ERROR', true);
                    reject(result.message)
                }
            })
            .catch((err)=>{
                commit('SET_LOADING', false);
                commit('SET_ERROR', true);
                reject(err)
            });
        });
    },
}