
import Client from "./client";
export default class Packages extends Client{
    constructor() {
        super();
    }
    listing(offset,limit,search){
        return new Promise(function(resolve,reject){
            this.get(`packages/list?offset=${offset}&limit=${limit}&search=${search}`)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    edit(data,id){
        return new Promise(function(resolve,reject){
            this.put(`packages/${id}`,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    detail(id){
        return new Promise(function(resolve,reject){
            this.get(`packages/${id}`)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    save(data){
        return new Promise(function(resolve,reject){
            this.post(`packages`,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}
