import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'

import "@/design/index.scss";
import VueSweetalert2 from 'vue-sweetalert2';
import store from '@/state/store'

import App from './App.vue'
import VueCookies from 'vue-cookies'
const axios = require('axios');
// import { initFirebaseBackend } from './authUtils'

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_APIKEY,
//   authDomain: process.env.VUE_APP_AUTHDOMAIN,
//   databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//   projectId: process.env.VUE_APP_PROJECTId,
//   storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
//   appId: process.env.VUE_APP_APPId,
//   measurementId: process.env.VUE_APP_MEASUREMENTID
// };

import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.csdatas';
import 'vue-toast-notification/dist/theme-sugar.css';
// initFirebaseBackend(firebaseConfig);
axios.get(`${process.env.VUE_APP_API}setting`)
.then(function (result) {
      result = result.data
      Vue.prototype.$logo = result.data.logo
      Vue.use(VueRouter)
      Vue.use(vco)
      Vue.config.productionTip = false
      
      Vue.use(BootstrapVue)
      Vue.use(Vuelidate)
      Vue.use(VueMask)
      Vue.use(VueSweetalert2);
      Vue.use(require('vue-moment'));
      Vue.config.productionTip = false
      Vue.use(VueCookies)
      Vue.use(VueToast);
      new Vue({
        router,
        store,
        render: h => h(App),
      }).$mount('#app')
})
